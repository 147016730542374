<template>
  <div class="home">
    <h1 class="title">Oddshawk Admin</h1>
  </div>
</template>

<script>

export default {
  name: 'Home'
};
</script>
