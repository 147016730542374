import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import sha256 from 'js-sha256';

Vue.use(Vuex);

const makeHash = (password) => {
  const time = Math.round(Date.now() / 1000);
  const hash = sha256.create();
  const hex = time.toString(16);
  hash.update(password + time);
  return hash + hex;
};

export default new Vuex.Store({
  state: {
    authenticated: false,
    email: '',
    password: '',
    possibles: [],
    dictionary: [],
    odds: [],
    urls: [],
    events: [],
    times: [],
    sports: [],
    markets: [],
    providers: [],
    competitions: [],
    drafts: [],
    socket: {
      ws: null,
      odds: [],
      status: {}
    },
    statusOdds: [],
    statusOddsDelayed: [],
    statusDraft: [],
    dbStatus: [],
    error: []
  },
  mutations: {
    authenticate (state, payload) {
      Vue.set(state, 'authenticated', payload.result);
      Vue.set(state, 'email', payload.email);
    },
    possibles (state, possibles) {
      for (const possible of possibles) {
        Object.freeze(possible);
      }
      state.possibles = possibles;
    },
    dictionary (state, dictionary) {
      state.dictionary = dictionary;
    },
    odds (state, odds) {
      state.odds = Object.freeze(odds);
    },
    events (state, events) {
      state.events = events;
    },
    sports (state, sports) {
      state.sports = sports;
    },
    markets (state, markets) {
      state.markets = markets;
    },
    competitions (state, competitions) {
      state.competitions = competitions;
    },
    providers (state, providers) {
      state.providers = providers;
    },
    drafts (state, drafts) {
      state.drafts = drafts;
    },
    urls (state, urls) {
      state.urls = Object.freeze(urls);
    },
    ws (state, ws) {
      state.socket.ws = ws;
    },
    resetSocket (state) {
      state.socket.ws = null;
      state.socket.odds.splice(0, state.socket.odds.length);
      state.socket.delta = 0;
      state.socket.worstDelta = 0;
      state.socket.sinceScrape = 0;
      state.socket.sinceChange = 0;
      state.socket.sinceUpdate = 0;
      state.socket.lastUpdate = 0;
      state.socket.lastChange = 0;
      state.socket.lastScrape = 0;
    },
    resetSocketOdds (state) {
      state.socket.filtered = true;
      state.socket.odds.splice(0, state.socket.odds.length);
      state.socket.delta = 0;
      state.socket.worstDelta = 0;
      state.socket.sinceScrape = 0;
      state.socket.sinceChange = 0;
      state.socket.sinceUpdate = 0;
      state.socket.lastUpdate = 0;
      state.socket.lastChange = 0;
      state.socket.lastScrape = 0;
    },
    updates (state, message) {
      const now = Date.now();
      message.data.forEach(item => {
        item.receiptTime = now;
        state.socket.lastChange = now - item.changed;
        if (item.changed && now - item.changed > state.socket.sinceChange) {
          state.socket.sinceChange = now - item.changed;
        }
        state.socket.lastUpdate = now - item.updated;
        if (now - item.updated > state.socket.sinceUpdate) {
          state.socket.sinceUpdate = now - item.updated;
        }
        item.initial = false;
        const index = state.socket.odds.findIndex(a => a.id === item.id);
        if (index > -1) {
          state.socket.odds.splice(index, 1, Object.freeze(item));
        } else {
          state.socket.odds.push(Object.freeze(item));
        }
      });
      state.socket.delta = Date.now() - message.time;
      if (state.socket.delta > state.socket.worstDelta) {
        state.socket.worstDelta = state.socket.delta;
      }
    },
    socketOdds (state, message) {
      message.data.forEach(a => {
        a.initial = true;
        state.socket.odds.push(Object.freeze(a));
      });
      console.log(state.socket.odds);
      state.socket.delta = Date.now() - message.time;
      if (state.socket.delta > state.socket.worstDelta) {
        state.socket.worstDelta = state.socket.delta;
      }
    },
    status (state, data) {
      state.socket.status = data;
    },
    statusOdds (state, payload) {
      const found = state.statusOdds.findIndex(a => a.sport === payload.sport && a.provider === payload.provider);
      if (found > -1) {
        Vue.set(state.statusOdds, found, payload);
      } else {
        state.statusOdds.push(payload);
      }
    },
    statusOddsDelayed (state, payload) {
      const found = state.statusOddsDelayed.findIndex(a => a.sport === payload.sport && a.provider === payload.provider);
      if (found > -1) {
        Vue.set(state.statusOddsDelayed, found, payload);
      } else {
        state.statusOddsDelayed.push(payload);
      }
    },
    statusDraft (state, payload) {
      const found = state.statusDraft.findIndex(a => a.sport === payload.sport && a.provider === payload.provider);
      if (found > -1) {
        Vue.set(state.statusDraft, found, payload);
      } else {
        state.statusDraft.push(payload);
      }
    },
    dbStatus (state, data) {
      Vue.set(state, 'dbStatus', data);
    },
    error (state, details) {
      state.error.push(details);
    }
  },
  actions: {
    authenticate ({ commit, dispatch, state }, payload) {
      return axios.get(process.env.VUE_APP_API_URL + '/authenticate', {
        headers: {
          'X-OH-User': payload.email,
          'X-OH-Hash': makeHash(payload.password)
        },
        withCredentials: true
      }).then((response) => {
        commit('authenticate', {
          result: response.data.authenticated && response.data.admin,
          email: payload.email
        });
        const ws = new WebSocket(process.env.VUE_APP_SOCKET_URL);
        commit('ws', ws);
        ws.onopen = () => {
          ws.send(JSON.stringify({
            message: 'auth',
            user: payload.email,
            hash: makeHash(payload.password)
          }));
        };

        ws.onclose = (e) => {
          console.log(e);
          commit('resetSocket');
        };

        ws.onerror = (e) => {
          console.log(e);
        };

        ws.addEventListener('message', (message) => {
          message = JSON.parse(message.data);
          switch (message.message) {
            case 'auth':
              setTimeout(() => {
                ws.send(JSON.stringify({
                  message: 'ping'
                }));
              }, 15000);
              ws.send(JSON.stringify({
                message: 'subscribe',
                filter: {
                  sport: 'Horse Racing',
                  event: {
                    name: 'false',
                    time: 0
                  }
                }
              }));
              break;
            case 'pong':
              setTimeout(() => {
                ws.send(JSON.stringify({
                  message: 'ping'
                }));
              }, 30000);
              break;
            case 'updates':
              if (state.socket.filtered) {
                commit('updates', message);
              }
              break;
            // fallthrough
            case 'initial':
              if (state.socket.filtered) {
                commit('socketOdds', message);
              }
              break;
            case 'status':
              commit('status', message);
              break;
          }
        });
      });
    },
    apiGet ({ state, commit }, url) {
      return axios.get(process.env.VUE_APP_API_URL + url, {
        headers: {
          'X-OH-User': state.email
        },
        withCredentials: true
      }).then((response) => {
        return response.data;
      }).catch(e => {
        commit('error', {
          url,
          error: e.message
        });
      });
    },
    apiPost ({ state, commit }, payload) {
      return axios.post(process.env.VUE_APP_API_URL + payload.url, payload.data, {
        headers: {
          'X-OH-User': state.email
        },
        withCredentials: true
      }).then((response) => {
        return response.data;
      }).catch(e => {
        commit('error', {
          url: payload.url,
          error: e.message
        });
      });
    },
    apiPut ({ state, commit }, payload) {
      return axios.put(process.env.VUE_APP_API_URL + payload.url, payload.data, {
        headers: {
          'X-OH-User': state.email
        },
        withCredentials: true
      }).then((response) => {
        return response.data;
      }).catch(e => {
        commit('error', {
          url: payload.url,
          error: e.message
        });
      });
    },
    apiPatch ({ state, commit }, payload) {
      return axios.patch(process.env.VUE_APP_API_URL + payload.url, payload.data, {
        headers: {
          'X-OH-User': state.email
        },
        withCredentials: true,
        data: {}
      }).then((response) => {
        return response.data;
      }).catch(e => {
        console.log(e);
        commit('error', {
          url: payload.url,
          error: e.message
        });
      });
    },
    apiDelete ({ state, commit }, url) {
      return axios.delete(process.env.VUE_APP_API_URL + url, {
        headers: {
          'X-OH-User': state.email
        },
        withCredentials: true
      }).then((response) => {
        return response.data;
      }).catch(e => {
        console.log(e);
        commit('error', {
          url,
          error: e.message
        });
      });
    },
    getPossibles ({ dispatch, commit }, payload) {
      return dispatch('apiGet', '/rest/possibles?type=' + payload.type + '&search=' + payload.search).then(possibles => {
        commit('possibles', possibles);
      });
    },
    getDictionary ({ dispatch, commit }, payload) {
      return dispatch('apiGet', '/rest/dictionary?type=' + payload.type + '&search=' + payload.search + (payload.sport ? '&sport=' + payload.sport : '') + '&sort=string&page=' + payload.page).then(dictionary => {
        commit('dictionary', dictionary);
      });
    },
    combineCanonicals ({ dispatch, commit }, selections) {
      return dispatch('apiPost', {
        url: '/rest/dictionary/combine',
        data: {
          selections
        }
      });
    },
    combineEvents ({ dispatch }, payload) {
      return dispatch('apiPost', {
        url: '/rest/event/combine',
        data: payload
      });
    },
    updateMatches ({ dispatch, commit }, payload) {
      return dispatch('apiPatch', {
        url: '/rest/dictionary/matches',
        data: payload
      });
    },
    getOdds ({ dispatch, commit }) {
      return dispatch('apiGet', '/rest/odds').then(odds => {
        commit('odds', odds);
      });
    },
    getRaceOdds ({ dispatch, commit }, race) {
      return dispatch('apiGet', '/rest/odds?limit=0&eventTime=' + encodeURIComponent(race.time) + '&eventName=' + encodeURIComponent(race.name)).then(odds => {
        commit('odds', odds);
      });
    },
    deleteEvent ({ dispatch, commit }, race) {
      return dispatch('apiDelete', '/rest/event/delete/' + race.id);
    },
    reverseEvent ({ dispatch }, event) {
      return dispatch('apiPost', {
        url: '/rest/event/reverse',
        data: {
          name: event.name,
          time: event.time
        }
      }).then(() => {
        return dispatch('deleteEvent', event);
      });
    },
    getMostRecentUpdate ({ dispatch, commit }, payload) {
      return dispatch('apiGet', '/rest/odds?limit=1&sortField=updated&sortDirection=-1&sport=' + payload.sport + '&provider=' + payload.provider).then(odds => {
        commit('statusOdds', {
          sport: payload.sport,
          provider: payload.provider,
          odds: odds[0]
        });
      });
    },
    getMostRecentDraft ({ dispatch, commit }, payload) {
      return dispatch('apiGet', '/rest/drafts?limit=1&sortField=updated&sortDirection=-1&sport=' + payload.sport + '&provider=' + payload.provider).then(odds => {
        commit('statusDraft', {
          sport: payload.sport,
          provider: payload.provider,
          odds: odds[0]
        });
      });
    },
    getOutOfDateUpdates ({ dispatch, commit }, payload) {
      return dispatch('apiGet', '/rest/odds?selectionStatus=ACTIVE&updatedBefore=' + (Date.now() - 800000) + '&sport=' + payload.sport + '&provider=' + payload.provider).then(odds => {
        commit('statusOddsDelayed', {
          sport: payload.sport,
          provider: payload.provider,
          odds: odds
        });
      });
    },
    getEvents ({ dispatch, commit }, query) {
      return dispatch('apiGet', '/rest/odds/events?' + (query || '')).then(events => {
        commit('events', events);
      });
    },
    getTimes ({ dispatch, commit }, query) {
      return dispatch('apiGet', '/rest/event/times?' + (query || '')).then(times => {
        commit('times', times);
      });
    },
    getSports ({ dispatch, commit }, query) {
      return dispatch('apiGet', '/rest/odds/sports?' + (query || '')).then(sports => {
        commit('sports', sports);
      });
    },
    getMarkets ({ dispatch, commit }, query) {
      return dispatch('apiGet', '/rest/odds/markets?' + (query || '')).then(markets => {
        commit('markets', markets);
      });
    },
    getCompetitions ({ dispatch, commit }, query) {
      return dispatch('apiGet', '/rest/odds/competitions?' + (query || '')).then(competitions => {
        console.log(competitions);
        commit('competitions', competitions);
      });
    },
    getProviders ({ dispatch, commit }, query) {
      return dispatch('apiGet', '/rest/odds/providers?' + (query || '')).then(providers => {
        commit('providers', providers);
      });
    },
    getDrafts ({ dispatch, commit }) {
      return dispatch('apiGet', '/rest/drafts').then(drafts => {
        commit('drafts', drafts);
      });
    },
    getUrls ({ dispatch, commit }) {
      return dispatch('apiGet', '/rest/url').then(urls => {
        commit('urls', urls);
      });
    },
    subscribe ({ commit, state }, filter) {
      commit('resetSocketOdds');
      state.socket.ws.send(JSON.stringify({
        message: 'subscribe',
        filter
      }));
    },
    addUrl ({ dispatch }, payload) {
      return dispatch('apiPost', { url: '/rest/url?url=' + encodeURIComponent(payload.url) + '&ability=' + payload.ability + '&time=' + payload.time + '&sport=' + payload.sport, data: {} }).then(() => {
        return dispatch('getUrls');
      });
    },
    removeUrl ({ dispatch }, payload) {
      return dispatch('apiDelete', '/rest/url?url=' + encodeURIComponent(payload.url) + '&ability=' + payload.ability).then(() => {
        return dispatch('getUrls');
      });
    },
    runUrl ({ dispatch }, payload) {
      return dispatch('apiPatch', { url: '/rest/url?url=' + encodeURIComponent(payload.url) + '&ability=' + payload.ability, data: {} }).then(() => {
        return dispatch('getUrls');
      });
    },
    getDbStatus ({ dispatch, commit }) {
      return dispatch('apiGet', '/rest/admin/db-status').then(status => {
        commit('dbStatus', status.data);
      });
    },
    createCanonical ({ dispatch }, payload) {
      return Promise.all([
        dispatch('apiPatch', {
          url: '/rest/possibles/' + encodeURIComponent(payload._id),
          data: { processed: true }
        }),
        dispatch('apiPut', {
          url: '/rest/dictionary',
          data: payload
        })
      ]).then(() => dispatch('getPossibles'));
    },
    updateCanonical ({ dispatch }, payload) {
      return dispatch('apiPatch', {
        url: '/rest/dictionary/string',
        data: payload
      });
    },
    mergePossible ({ dispatch }, payload) {
      return dispatch('apiPost', {
        url: '/rest/dictionary/combinePossible',
        data: payload
      }).then(() => dispatch('getPossibles', {
        type: payload.type,
        search: ''
      }));
    },
    getStatus ({ state, dispatch }) {
      if (state.socket.ws && state.socket.ws.readyState === 1) {
        state.socket.ws.send(JSON.stringify({
          message: 'status'
        }));
        setTimeout(() => {
          dispatch('getStatus');
        }, 300000);
      } else {
        setTimeout(() => {
          dispatch('getStatus');
        }, 1000);
      }
    },
    changeProvider ({ state }, payload) {
      if (state.socket.ws && state.socket.ws.readyState === 1) {
        state.socket.ws.send(JSON.stringify(
          {
            message: 'switch-scrape-provider',
            sport: payload.sport,
            provider: payload.provider,
            switch: payload.switch,
            from: payload.from
          }
        ));
      }
    },
    holdProvider ({ state }, payload) {
      if (state.socket.ws && state.socket.ws.readyState === 1) {
        state.socket.ws.send(JSON.stringify(
          {
            message: 'hold-scrape-provider',
            sport: payload.sport,
            provider: payload.provider,
            lock: payload.hold
          }
        ));
      }
    },
    rebootIndex ({ state, dispatch }, index) {
      if (state.socket.ws && state.socket.ws.readyState === 1) {
        state.socket.ws.send(JSON.stringify({
          message: 'scraper-reboot',
          index
        }));
        setTimeout(() => {
          dispatch('getStatus');
        }, 1000);
      }
    },
    reboot ({ state, dispatch }) {
      if (state.socket.ws && state.socket.ws.readyState === 1) {
        state.socket.ws.send(JSON.stringify({
          message: 'reboot'
        }));
      }
    }
  },
  getters: {
    events: (state) => {
      return state.events.sort((a, b) => a.time - b.time);
    }
  },
  modules: {
  }
});
